div .passwordbox {
    -webkit-box-flex: 2;
    -webkit-flex-grow: 2;
    -ms-flex-positive: 2;
    flex-grow: 2;
    position: relative;
    border-style: solid;
    border-color: rgba(128,128,128,0.2);
    border-width: 1px;
    border-bottom-color: rgba(128,128,128,0.2);
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.passwordbox input {
    background-color: #ffffff;
    box-sizing: border-box;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-top: 4px;
    padding-right: 12px;
    padding-bottom: 4px;
    padding-left: 12px;
    outline: none;
    font-size: 14px;
    font-family: ArtifaktElement,sans-serif;
    font-weight: 600;
    line-height: 1.4;
    width: 100%;
    color: #3c3c3c;
    height: calc(36px - 2px);
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

.buttonsContainer {
    display: flex;
    flex-flow: row-reverse;
    padding-bottom: 0;
}

.buttonsContainer button {
    width: 102px;
}

.loginform article {
    height: 338px;
}

.invalid-login {
    color: red !important;
    font-size: 10px !important;
    text-align: right !important;
}

.loginMainContainer {
    display: flex;
    flex-flow: column;
    height: 100%;
    padding-top: 29px;
    overflow: hidden;
}

.loginMainContainer header button {
    display: none;
}