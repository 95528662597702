.confirmDialog {
    display: flex;
    flex-flow: row-reverse;
    padding-bottom: 0;
}

.confirmDialog button {
    width: 120px;
}

.confirmDialog .red button {
    background-color: red;
}