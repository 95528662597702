.panels {
    overflow-y: scroll;
}

.card-title {
    white-space: nowrap;
}

.card-footer {
    white-space: nowrap;
}

.card-body {
    min-height: 172px;
}

.breadcrumb-container {
    display: flex;
    flex-direction: row;
}

.breadcrumb-container div {
    margin: 3px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.panel-qr-code-container {
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    padding-right: 20px;
}