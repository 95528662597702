div.MuiTypography-root p {
    white-space: nowrap;
    text-overflow: ellipsis;
}

.panels-generation-timeline-container {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    height: 200px;
}

.panels-generation-status {
    bottom: 210px;
}