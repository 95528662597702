.models {
    margin-top: 15px;
    overflow-y: scroll;
}

#upload-file-form .fileBrowseContainer {
    display: flex;
}

#upload-file-form .fileBrowseContainer button {
    /* following are to override IconButton strange behavior */
    outline: none;
    border-color: transparent;
    box-shadow: none;
}

#upload-file-form .stretch {
    width: -webkit-fill-available;
}

#upload-file-form .browseButton {
    display: flex;
    flex-flow: row;
    align-items: flex-end;
}

#upload-file-form .browseButton label {
    margin: 0;
    height: 36px; /* to match inner content (the button) exactly */
}

#modelFileInput {
    display: none;
}

#upload-file-form .buttonsContainer {
    display: flex;
    flex-flow: row-reverse;
    padding-bottom: 0;
}

#upload-file-form textarea {
    height: 100px !important;
}

#upload-file-form .buttonsContainer button {
    width: 102px;
}

.upload-fail-message {
    color: red !important;
    font-size: 10px !important;
    text-align: right !important;
    padding-right: 36px !important;
}

#upload-file-form .creation-failed-message {
    color: red !important;
    font-size: 10px !important;
    text-align: right !important;
    margin-bottom: 10px;
}

.checkboxfield {
    position: relative;
}

.stretch .checkboxtext {
    position: absolute;
    top: 0;
    left: 25px;
    font-size: 14px;
    font-family: ArtifaktElement,sans-serif;
}

.processing-ring-percentage {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 35px;
    height: 47%;
}

.push-panel-to-dextall-studio-button {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px;
    background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M11%2C11H3V3h8ZM4%2C4v6h6V4Z%22%3E%3C%2Fpath%3E%3Cpath%20d%3D%22M21%2C11H13V3h8ZM14%2C4v6h6V4Z%22%3E%3C%2Fpath%3E%3Cpath%20d%3D%22M11%2C21H3V13h8ZM4%2C14v6h6V14Z%22%3E%3C%2Fpath%3E%3Cpath%20d%3D%22M21%2C21H13V13h8Zm-7-7v6h6V14Z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E)
}