.modelContainer {
    width: calc(100% - 296px);
}

.inRow {
    display: flex;
    flex-flow: row!important;
}

.parametersContainer {
    display: flex;
    flex-flow: column;
    width: 300px;
    background-color: white;
    padding: 8px;
}

.parametersPreview {
    padding-bottom: 6px;
    font-size: 12px;
    font-family: ArtifaktElement, sans-serif;
    font-weight: 300;
    color: #3c3c3c;
    line-height: 18px;
}

.viewer {
    background-color: white;
    width: 100%;
    position: relative;
    border-style: solid;
    border-width: 8px;
    border-color: #D9D9D9;
    height: 100%;
}

.model-selection-container {
    position: absolute;
    top: 10px;
    z-index: 5000;
    left: 10px;
}